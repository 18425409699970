<template>
  <div class="m-b">
    <erp-label tag="div" class="erp-label-white w-full" label="Taxas, Impostos e Comissões">
      <e-row mr class="justify-between m-t">
        <div class="col" style="max-width: 340px">
          <sistema-taxacao-select label="Sistema de taxação:" label-width="140px" field-view="lr"
                                  v-model="leilao.sistemaTaxa" @data="processTaxasData"/>
        </div>
      </e-row>

      <e-row mr class="justify-between" v-if="leilao.sistemaTaxaObject && leilao.sistemaTaxaObject.taxas">
        <e-col style="margin-left: 38px">
          <div v-for="taxa in leilao.sistemaTaxaObject.taxas">
            <div class="m-t-xs">{{ taxa.nome }}: <span
                v-if="taxa.tipo === TipoTaxa.TIPO_VALOR_FIXO">R$ </span>{{ taxa.valor }}
              <span v-if="taxa.tipo === TipoTaxa.TIPO_PORCENTAGEM">%</span></div>
          </div>
          <!--<div>ICMS: {{(leilao.taxas.icms || 0)|moeda}}%</div>
          <div class="m-t-xs">Comissão: {{(leilao.taxas.comissao || 0)|moeda}}%</div>
          <div class="m-t-xs">ISS: {{(leilao.taxas.iss || 0)|moeda}}%</div>
          <div class="m-t font-bold">Configuração dinâmica de taxas</div>
          <div class="m-t">Nenhuma taxa dinâmica configurada. Crie no sistema de taxação taxas administrativas e
            valores baseados no tipo de leilao.
          </div>-->
        </e-col>

      </e-row>
    </erp-label>
  </div>
</template>

<script>
import ECol from "components/layout/components/Col.vue"
import SistemaTaxacaoSelect from "components/leiloes/components/include/SistemaTaxacaoSelect.vue"
import {ErpLabel} from "@/plugins/uloc-erp"
import ERow from "components/layout/components/Row.vue"
import * as TipoTaxa from "@/domain/taxas/helpers/Status"

export default {
  name: "LeilaoImpostos",
  props: {
    leilao: {required: true}
  },
  computed: {
    TipoTaxa() {
      return TipoTaxa
    },
  },
  components: {
    ERow,
    ErpLabel,
    SistemaTaxacaoSelect,
    ECol
  },
  methods: {
    processTaxasData(data) {
      // console.log
      this.leilao.sistemaTaxaObject = data
    }
  },
}
</script>
