<template>
  <div class="m-t-lg">
    <erp-label tag="div" class="erp-label-white w-full" label="Local de realização">

      <e-row mr class="justify-between m-t">
        <div class="col" style="max-width: 340px">
          <erp-s-field
              view="lr"
              label-width="90px"
              label="Pátio:"
              class="wrap label-wrap"
          >
            <patio-input v-model="leilao.patio" @data="(data) => $emit('processPatioData', data)"
                         :finded-data-obj="extra.patio.id ? extra.patio : null"/>
            <!-- TODO: Verificar conflito de endereço se for digitado e selecionado pátio -->
          </erp-s-field>
        </div>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field
              view="lr"
              label-width="90px"
              tag="div"
          >
            <erp-checkbox class="flex items-center" v-model="extra.digitarEndereco">
              <label class="no-select font-bold">
                <span class="m-l-xs">Desejo digitar o endereço</span>
              </label>
            </erp-checkbox>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="content-stretch m-t">
        <e-col style="max-width: 220px">
          <erp-s-field
              view="lr"
              label-width="90px"
              label="CEP:"
              class="wrap label-wrap"
          >
            <erp-input size="2" :disable="!extra.digitarEndereco" @keyup="pesquisarCep" v-model="leilao.cep"
                       v-mask="'##.###-###'"/>
          </erp-s-field>
        </e-col>
        <e-col class="m-b-none">
          <e-row mr class="justify-end">
            <div class="col self-end" style="max-width: 180px;">
              <erp-s-field
                  view="lr"
                  label="UF:"
              >
                <erp-input size="2" :disable="!extra.digitarEndereco" v-model="leilao.uf"/>
              </erp-s-field>
            </div>

            <div class="col" style="max-width: 202px">
              <div class="row flex justify-end">
                <div class="col self-end" style="max-width: 300px">
                  <erp-s-field
                      view="lr"
                      label="Cidade:"
                  >
                    <erp-input size="2" :disable="!extra.digitarEndereco" v-model="leilao.cidade"/>
                  </erp-s-field>
                </div>
              </div>
            </div>
          </e-row>
        </e-col>

      </e-row>

      <e-row mr class="justify-between">

        <e-col>
          <erp-s-field
              view="lr"
              label="Endereço:"
              label-width="90px"
          >
            <erp-input size="2" :disable="!extra.digitarEndereco" v-model="leilao.endereco"/>
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 150px">
          <erp-s-field
              view="lr"
              label="Número:"
              class="wrap label-wrap"
          >
            <erp-input size="2" :disable="!extra.digitarEndereco" v-model="leilao.enderecoNumero"/>
          </erp-s-field>
        </e-col>

        <e-col class="m-l" style="max-width: 180px">
          <erp-s-field
              view="lr"
              label="Bairro:"
          >
            <erp-input size="2" :disable="!extra.digitarEndereco" v-model="leilao.bairro"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              view="lr"
              label="Referência:"
              label-width="90px"
          >
            <erp-input size="2" :disable="!extra.digitarEndereco" v-model="leilao.enderecoReferencia"/>
          </erp-s-field>
        </e-col>
      </e-row>
    </erp-label>
  </div>
</template>

<script>
import {ErpCheckbox, ErpInput, ErpLabel, ErpSField} from "@/plugins/uloc-erp"
import ECol from "components/layout/components/Col.vue"
import ERow from "components/layout/components/Row.vue"
import PatioInput from "components/patio/helpers/input/PatioInput.vue"
import {debounce} from "uloc-vue";
import {buscaCep} from "@/domain/utils/cep";

export default {
  name: "LeilaoLocal",
  props: {
    leilao: {required: true},
    extra: {required: true}
  },
  methods: {
    pesquisarCep: debounce(function () {
      const cep = String(this.leilao.cep).replace(/\D/g, '')
      if (cep.length !== 8) {
        return
      }
      buscaCep(cep)
          .then(({data}) => {
            console.log(data)
            if (data.erro) {
              this.$uloc.notify({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'CEP não encontrado'
              })
              return
            }
            this.leilao.endereco = data.logradouro
            this.leilao.enderecoReferencia = data.complemento
            this.leilao.bairro = data.bairro
            this.leilao.cidade = data.localidade
            // this.form.pessoa.addresses[0].cityId = data.ibge
            this.leilao.uf = data.uf
            // this.$refs.endNumero.focus()
          })
          .catch((error) => {
            console.log(error)
          })
    }, 500)
  },
  components: {PatioInput, ERow, ErpLabel, ErpInput, ErpCheckbox, ECol, ErpSField}
}
</script>
