<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :label="label || 'Sistema de taxação'"
      :label-width="labelWidth"
      :helper="helper"
      :error="error"
      :helper-position="helperPosition || 'lb'"
  >
    <erp-select
        v-if="!binding"
        @input="set"
        :loading="loading"
        :placeholder="placeholder || 'Selecione'"
        :options="asyncdata"
        v-model="model"
        :multiple="multiple"
        :simpleBorder="simpleBorder"
    ></erp-select>
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'
import {list} from '@/domain/taxas/services'

export default {
  name: 'SistemaTaxacaoSelect',
  mixins: [SelectMixin],
  props: {
  },
  data () {
    return {
      binding: false,
      asyncdata: []
    }
  },
  computed: {},
  mounted () {
    this.load()
  },
  components: {},
  methods: {
    load () {
      this.loading = true
      list(100, 1, '')
        .then((response) => {
          this.loading = false
          if (!response.data || !response.data.result || !Array.isArray(response.data.result)) {
            return
          }
          let list = response.data.result.map((obj) => {
            return {
              ...obj,
              label: obj.nome,
              value: obj.id
            }
          })

          !this.multiple && !this.required && list.unshift({label: this.placeholder || 'Selecione', value: null})
          this.asyncdata = list
         /* this.binding = true
          if (list && list.length && this.model && this.model.id) {
            this.asyncdata.map(d => {
              if (d.id === this.model.id) {
                this.model.value = d
              }
            })
          }
          setTimeout(() => {
            this.binding = false
          }, 300)*/
        })
        .catch((error) => {
          this.loading = false

        })
    },
    set (e) {
      let val = e && e.target ? e.target.value : e
      let find = this.asyncdata.find((v) => v.id === val)
      if(find){
        this.$emit('data', find)
      }
      this.__set(e)
    },
  }
}
</script>
