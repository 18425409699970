<template>
  <div>
    <div>
      <h2 class="diviser m-b-lg column">
        <div>Informações sobre modificações no leilão</div>
      </h2>
      <div class="m-t">
        <e-row mr>
          <e-col>
            <erp-s-field label="Data de Criação">
              {{leilao.createdAt|formatDate('dd/MM/yyyy HH:mm:ss')}}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Criado Por">
              {{leilao.createdByName}}
              <div><small class="font-10">Login: {{leilao.createdByUser}}</small></div>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Última Modificação">
              {{leilao.dateModified|formatDate('dd/MM/yyyy HH:mm:ss')}}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Modificado Por">
              {{leilao.modifiedByName}}
              <div><small class="font-10">Login: {{leilao.modifiedByUser}}</small></div>
            </erp-s-field>
          </e-col>
        </e-row>
      </div>
    </div>
  </div>
</template>

<script>
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import {ErpSField} from "uloc-vue-plugin-erp"

export default {
  name: "LeilaoInfo",
  components: {
    ECol,
    ERow,
    ErpSField
  },
  props: ['leilao'],
  methods: {
  }
}
</script>
